var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAdmin || _vm.isCoordinador
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function({ on, attrs }) {
                  return [
                    _c(
                      "v-autocomplete",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "parameter-select",
                            class: _vm.getParameterValueColor(
                              _vm.value,
                              _vm.aiValue,
                              _vm.selectedFixedValue || _vm.fixedValue
                            ),
                            attrs: {
                              label: _vm.label,
                              items: _vm.options,
                              "item-text": "name",
                              "item-value": "value",
                              "search-input": _vm.search,
                              disabled: _vm.disabled
                            },
                            on: {
                              input: _vm.updateValue,
                              "update:searchInput": function($event) {
                                _vm.search = $event
                              },
                              "update:search-input": function($event) {
                                _vm.search = $event
                              }
                            },
                            model: {
                              value: _vm.selectedValue,
                              callback: function($$v) {
                                _vm.selectedValue = $$v
                              },
                              expression: "selectedValue"
                            }
                          },
                          "v-autocomplete",
                          attrs,
                          false
                        ),
                        on
                      )
                    )
                  ]
                }
              }
            ],
            null,
            false,
            2620616131
          )
        },
        [
          _vm.parameter !== "isWaterColored"
            ? _c("span", [
                _c("strong", [_vm._v("Manual:")]),
                _vm._v(
                  "\n    " +
                    _vm._s(_vm._f("physicistChemicalParam")(_vm.value)) +
                    "\n    "
                ),
                _c("br"),
                _c("strong", [_vm._v("Automático:")]),
                _vm._v(
                  "\n    " +
                    _vm._s(_vm._f("physicistChemicalParam")(_vm.aiValue)) +
                    "\n    "
                ),
                _c("br"),
                _c("strong", [_vm._v("Valor corrigido:")]),
                _vm._v(
                  "\n    " +
                    _vm._s(
                      _vm._f("physicistChemicalParam")(_vm.selectedFixedValue)
                    ) +
                    "\n  "
                )
              ])
            : _c("span", [
                _vm._v(
                  '\n    "Sim" para água verde ou outra cor aparente e "Não" para água incolor.\n  '
                )
              ])
        ]
      )
    : _c(
        "span",
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.label,
              value: _vm.getPhysicistChemicalParam(_vm.value),
              disabled: ""
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }