var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-layout",
        { staticClass: "grey lighten-3", attrs: { "justify-center": "" } },
        [_c("v-card-title", [_c("h3", [_vm._v("Parâmetros")])])],
        1
      ),
      _c(
        "v-layout",
        { attrs: { wrap: "", "ma-3": "" } },
        [
          _c(
            "v-flex",
            { attrs: { sm12: "", md10: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Volume",
                              value: _vm.analyze.pool.volume + " m³",
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Alcalinidade",
                              parameter: "alkalinity",
                              parameterFixed: "fixedAlkalinity",
                              value: _vm.analyze.alkalinity,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiAlkalinity,
                              fixedValue: _vm.analyze.fixedAlkalinity
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Cloro total",
                              parameter: "totalChlorine",
                              parameterFixed: "fixedTotalChlorine",
                              value: _vm.analyze.totalChlorine,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiTotalChlorine,
                              fixedValue: _vm.analyze.fixedTotalChlorine
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Cianúrico",
                              parameter: "cyanuric",
                              parameterFixed: "fixedCyanuric",
                              value: _vm.analyze.cyanuric,
                              aiValue: _vm.analyze.aiCyanuric,
                              fixedValue: _vm.analyze.fixedCyanuric,
                              analyzeId: _vm.analyze.id
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl3: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Água",
                              parameter: "water",
                              parameterFixed: "fixedWater",
                              value: _vm.analyze.water,
                              aiValue: _vm.analyze.aiWater,
                              fixedValue: _vm.analyze.fixedWater,
                              analyzeId: _vm.analyze.id
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Cloro livre",
                              parameter: "freeChlorine",
                              parameterFixed: "fixedFreeChlorine",
                              value: _vm.analyze.freeChlorine,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiFreeChlorine,
                              fixedValue: _vm.analyze.fixedFreeChlorine
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Dureza",
                              parameter: "hardness",
                              parameterFixed: "fixedHardness",
                              value: _vm.analyze.hardness,
                              aiValue: _vm.analyze.aiHardness,
                              fixedValue: _vm.analyze.fixedHardness,
                              analyzeId: _vm.analyze.id
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "PH",
                              parameter: "ph",
                              parameterFixed: "fixedPh",
                              value: _vm.analyze.ph,
                              aiValue: _vm.analyze.aiPh,
                              fixedValue: _vm.analyze.fixedPh,
                              analyzeId: _vm.analyze.id
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Temperatura",
                              value:
                                _vm.analyze.temperature != "99"
                                  ? _vm.analyze.temperature + " °C"
                                  : "Indisponível",
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl3: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Superfície",
                              value: _vm.getPhysicistChemicalParam(
                                _vm.analyze.surface
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "gH",
                              parameter: "gh",
                              parameterFixed: "fixedGh",
                              value: _vm.analyze.gh,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiGh,
                              fixedValue: _vm.analyze.fixedGh
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "kH",
                              parameter: "kh",
                              parameterFixed: "fixedKh",
                              value: _vm.analyze.kh,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiKh,
                              fixedValue: _vm.analyze.fixedKh
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Nitrito",
                              parameter: "nitrite",
                              parameterFixed: "fixedNitrite",
                              value: _vm.analyze.nitrite,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiNitrite,
                              fixedValue: _vm.analyze.fixedNitrite
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Nitrato",
                              parameter: "nitrate",
                              parameterFixed: "fixedNitrate",
                              value: _vm.analyze.nitrate,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiNitrate,
                              fixedValue: _vm.analyze.fixedNitrate
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl3: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Amônia Total",
                              parameter: "totalAmmonia",
                              parameterFixed: "fixedTotalAmmonia",
                              value: _vm.analyze.totalAmmonia,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiTotalAmmonia,
                              fixedValue: _vm.analyze.fixedTotalAmmonia
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Fundo",
                              value: _vm.getPhysicistChemicalParam(
                                _vm.analyze.bottom
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Borda",
                              value: _vm.getPhysicistChemicalParam(
                                _vm.analyze.edge
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Calçada",
                              value: _vm.getPhysicistChemicalParam(
                                _vm.analyze.sidewalk
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "parameter",
                      attrs: { sm12: "", md3: "", xl2: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("ParameterSelect", {
                            attrs: {
                              label: "Sal",
                              parameter: "salt",
                              parameterFixed: "fixedSalt",
                              value: _vm.analyze.salt,
                              analyzeId: _vm.analyze.id,
                              aiValue: _vm.analyze.aiSalt,
                              fixedValue: _vm.analyze.fixedSalt
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isAdmin || _vm.isCoordinador
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "parameter",
                          attrs: { sm12: "", md3: "", xl3: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            [
                              _c("ParameterSelect", {
                                attrs: {
                                  label: "Coloração na água",
                                  parameter: "isWaterColored",
                                  parameterFixed: "isWaterColored",
                                  value: _vm.analyze.isWaterColored,
                                  analyzeId: _vm.analyze.id,
                                  disabled: false
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.isAdmin || _vm.isCoordinador
            ? _c(
                "v-flex",
                {
                  staticClass: "text-no-wrap",
                  attrs: { sm12: "", md12: "", xl2: "" }
                },
                [
                  _c("div", { staticClass: "toolbar-switch" }, [
                    _c("b", [_vm._v("Usar no treinamento:")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "toolbar-switch" },
                    [
                      _c("PiscinappSwitch", {
                        on: { input: _vm.saveIsRevised },
                        model: {
                          value: _vm.isRevised,
                          callback: function($$v) {
                            _vm.isRevised = $$v
                          },
                          expression: "isRevised"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }