<template>
  <v-tooltip bottom v-if="isAdmin || isCoordinador">
    <template v-slot:activator="{ on, attrs }">
      <v-autocomplete
        :label="label"
        v-bind="attrs"
        v-on="on"
        :items="options"
        class="parameter-select"
        :class="getParameterValueColor(value, aiValue, selectedFixedValue || fixedValue)"
        v-model="selectedValue"
        @input="updateValue"
        item-text="name"
        item-value="value"
        :search-input.sync="search"
        :disabled="disabled"
      ></v-autocomplete>
    </template>
    <span v-if="parameter !== 'isWaterColored'">
      <strong>Manual:</strong>
      {{ value | physicistChemicalParam }}
      <br />
      <strong>Automático:</strong>
      {{ aiValue | physicistChemicalParam }}
      <br />
      <strong>Valor corrigido:</strong>
      {{ selectedFixedValue | physicistChemicalParam }}
    </span>
    <span v-else>
      "Sim" para água verde ou outra cor aparente e "Não" para água incolor.
    </span>
  </v-tooltip>
  <span v-else>
    <v-text-field :label="label" :value="getPhysicistChemicalParam(value)" disabled></v-text-field>
  </span>
</template>

<script>
import parametersDictionary from '@/parametersDictionary.json';
import { updateAnalyze } from '@/services/AnalyzeService';
import { alertSnack, isRole } from '@/services/UtilsService';

export default {
  props: [
    'parameter',
    'parameterFixed',
    'value',
    'aiValue',
    'fixedValue',
    'analyzeId',
    'label',
    'disabled',
  ],
  data() {
    const options = parametersDictionary[this.parameter];

    let valueString;

    if (this.parameter !== 'isWaterColored') {
      valueString = String(this.isValidValue(this.fixedValue) ? this.fixedValue : this.value);
    }

    const isEmptyValue = valueString === '99';
    const hasSelectedOption = options.some((option) => String(option.value) === valueString);
    if (!hasSelectedOption && !isEmptyValue && this.parameter !== 'isWaterColored') {
      options.unshift({
        value: valueString,
        name: (Number.isNaN(Number(valueString))
          ? valueString
          : Number(valueString)
        ).toLocaleString('pt-BR'),
      });
    }
    return {
      selectedValue: !isEmptyValue ? valueString || this.value : '',
      options,
      selectedFixedValue: this.fixedValue,
      isAdmin: isRole('ADMIN'),
      isCoordinador: isRole('COORDINADOR'),
      search: null,
    };
  },
  watch: {
    search(val) {
      const normalizedVal = val.replace(',', '.');
      if (
        !this.options.some((option) => {
          const normalizedOption = String(option.value).replace(',', '.');
          const normalizedOptionName = String(option.name).replace(',', '.');
          return normalizedOption === normalizedVal || normalizedOptionName === normalizedVal;
        })
      ) {
        const newOption = {
          value: val,
          name: val.toLocaleString('pt-BR'),
          isCustom: true,
        };
        if (this.options.some((option) => option.isCustom)) {
          const customOptionIndex = this.options.findIndex((option) => option.isCustom);
          this.options.splice(customOptionIndex, 1, newOption);
        } else {
          this.options.unshift(newOption);
        }
      }
    },
  },
  methods: {
    updateValue() {
      let valueToUpdate;
      if (
        this.selectedValue === false ||
        this.selectedValue === true ||
        this.selectedValue === null
      ) {
        valueToUpdate = this.selectedValue;
      } else {
        valueToUpdate = !Number.isNaN(Number(this.selectedValue.replace(',', '.')))
          ? parseFloat(this.selectedValue.replace(',', '.'))
          : this.selectedValue;
      }
      updateAnalyze(this.$apollo, {
        id: this.analyzeId,
        data: { [this.parameterFixed]: valueToUpdate },
      })
        .then(() => {
          alertSnack('Atualizado com sucesso');
        })
        .catch(() => {
          alertSnack('Erro ao atualizar', true);
        });
      this.selectedFixedValue = this.selectedValue;
    },
    isValidValue(value) {
      return value !== 99 && value !== 'INDISPONIVEL' && value !== undefined && value !== null;
    },
    getParameterValueColor(value, aiValue, fixedValue) {
      if (this.isValidValue(fixedValue)) {
        return 'green-line';
      }
      if (this.isValidValue(aiValue) && aiValue !== value) {
        return 'red-line';
      }
      return 'black-line';
    },
    getPhysicistChemicalParam(value) {
      if (value === true) {
        return 'Sim';
      }
      if (value === false) {
        return 'Não';
      }
      if (value === 99 || value === 'INDISPONIVEL' || value === undefined || value === null) {
        return 'Não definido';
      }
      if (value === 98) {
        return 'Indisponível';
      }
      return Number.isNaN(Number(value)) ? value : Number(value).toLocaleString('pt-BR');
    },
  },
  filters: {
    physicistChemicalParam(value) {
      if (value === 99 || value === 'INDISPONIVEL' || value === undefined || value === null) {
        return 'Não definido';
      }
      if (value === 98) {
        return 'Indisponível';
      }
      return Number.isNaN(Number(value)) ? value : Number(value).toLocaleString('pt-BR');
    },
  },
};
</script>

<style scoped>
.parameter-select {
  margin-left: 4px;
  cursor: pointer;
  min-width: 16px;
}

.green-line >>> .v-select__slot {
  box-shadow: 0 1px 0 0 #92cd1d;
}

.red-line >>> .v-select__slot {
  box-shadow: 0 1px 0 0 #f44336;
}

.black-line >>> .v-select__slot {
  box-shadow: 0 1px 0 0 #000000;
}
</style>
